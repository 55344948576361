import React , { useEffect,useState } from 'react'
import { useParams, useLocation, Link, Navigate, useNavigate } from 'react-router-dom'
import SectionHeading from '../../SectionHeading'
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { ToastContainer, toast } from 'react-toastify';
import Div from '../../Div'
import Spacing from '../../Spacing'
import { useSelector } from 'react-redux'
import Accordion from '../../Accordion'
import axios from 'axios'
import Slider from 'react-slick'
import { Icon } from '@iconify/react'
import FaqforCC from '../FaqforCC'
import { accordionDataRDInDigitalMarketing } from '../../Accordion/AccordionData'
import { Box } from '@mui/material'
import { Helmet } from "react-helmet";
import PageHeadingService from '../../PageHeading/PageHeadingService'
import { Button } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel';
import PhoneInput from 'react-phone-number-input';
import Checkbox from '@mui/material/Checkbox';
import LeadforgeContact from '../Leadforgecontact';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const ResearchAndDevelopment = () => {
  const colordata = useSelector((state) => state && state.colordata)
  const location = useLocation();
  const params = useParams() 
const navigate = useNavigate()
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false
  };

const [open, setOpen] = React.useState(false);
const [services, setServices] = useState([]);
const [indexPos, setIndexPos] = React.useState(location?.state?.index || 5);
const [individualService, setIndividualService] = React.useState(null);
const [contactdetl, setContactdetl] = useState({ firstname: '', lastname: '', email: '', company: '', phonenumber: "" });
const [errror, setError] = useState({ firstname: '', lastname: '', email: '', company: '', phonenumber: "" });
const [btndisable, setBtndisable] = useState(false);

  useEffect(() => {
    if (services.length > 0 && indexPos >= 0 && indexPos < services.length) {
      setIndividualService(services[indexPos]);
    }
  }, [services, indexPos]); 
  
  // Effect to fetch services (this should run only when index or params change)
  useEffect(() => {
    if (params.serviceDetailsId === 'service-details') {
  
      getcategorywithSerice(); // Fetch services when index or params change
  
    }
    else if (params.serviceDetailsId === 'd2g'){
      getcategorywithSerice(); // Fetch services when index or params change
     
    }
    else{
      getcategorywithSerice(); // Fetch services when index or params change
      
    }
  }, [params, location]); // This effect runs when `params` or `location` changes
  
  const getcategorywithSerice = async () => {
    try {
        const response = await axios.get(process.env.REACT_APP_BASE_URL + 'category/CategoryWithServices');
        const data = response.data;
  
        if(params.serviceDetailsId === 'service-details'){
            const filterData =  data.categoriesWithServices.filter(categories => categories.category_name === "D2C")
  
            const updatedServices = filterData.map((service) => {
              const updatedServiceDetails = service.services.map((subService) => {
                  // Safely handle the service_name and replace spaces with dashes
                  let updatedName = subService?.service_name ? subService.service_name.replace(/\s+/g, "-").toLowerCase() : '';
          
                  // Special cases for service_name
                  if (subService.service_name === "Content Creation – Photo and videography") {
                      updatedName = "content-creation";
                  } else if (subService.service_name === "Digital Strategy and Media Buying") {
                      updatedName = "digital-media-buying-agency";
                  } else if (subService.service_name === "Social Media Management") {
                      updatedName = "social-media-management";
                  }else if (subService.service_name === "UI/UX Design") {
                    updatedName = "UI-UX-Design";
                }else if (subService.service_name === "Lead Generation and Acquisition") {
                  updatedName = "Lead-Generation-and-Acquisition";
              }
          
                  // Return the updated subService with modified service_name
                  return {
                      ...subService,
                      service_name: updatedName,
                  };
              });
          
              // Return the updated service object with modified service_names in the services array
              return {
                  ...service,
                  services: updatedServiceDetails,  // Replace old services with updated services
              };
          });
  
            setServices(updatedServices[0].services)
            return;
        }
  
  
        if(params.serviceDetailsId === 'data-mining' || params.serviceDetailsId === 'advisory-consulting'){
            const filterData =  data.categoriesWithServices.filter(categories => categories.category_name === "D2G")
    
            setServices(filterData[0].services)
            return;
        }
        
        if(params.serviceDetailsId === 'leadforge'){
            const filterData =  data.categoriesWithServices.filter(categories => categories.category_name === "D2B")
            setServices(filterData[0].services)
            return;
        }
  
        else {
            const filterData =  data.categoriesWithServices.filter(categories => categories.category_name === "D2C");
  
            const updatedServices = filterData.map((service) => {
          
              // Check if service.services is an array and map through it
              const updatedServiceDetails = service.services.map((subService) => {
                  // Safely handle the service_name and replace spaces with dashes
                  let updatedName = subService?.service_name ? subService.service_name.replace(/\s+/g, "-").toLowerCase() : '';
          
                  // Special cases for service_name
                  if (subService.service_name === "Content Creation – Photo and videography") {
                      updatedName = "content-creation";
                  } else if (subService.service_name === "Digital Strategy and Media Buying") {
                      updatedName = "digital-media-buying-agency";
                  } else if (subService.service_name === "Social Media Management") {
                      updatedName = "social-media-management";
                  }else if (subService.service_name === "UI/UX Design") {
                    updatedName = "UI-UX-Design";
                }else if (subService.service_name === "Lead Generation and Acquisition") {
                  updatedName = "Lead-Generation-and-Acquisition";
              }
          
                  // Return the updated subService with modified service_name
                  return {
                      ...subService,
                      service_name: updatedName,
                  };
              });
          
              // Return the updated service object with modified service_names in the services array
              return {
                  ...service,
                  services: updatedServiceDetails,  // Replace old services with updated services
              };
          });
  
            setServices(updatedServices[0].services)
            return;
        }
  
    } catch (error) {
        console.log(error, 'error');
    }
  }

  const formatRoute = (serviceName) => {
    return serviceName
      .replace(/–/g, '-') 
      .replace(/\s+/g, '-') 
      .toLowerCase() 
      .replace(/-$/, '');  
    };
    const serviceRouteMap1 = {
      "Content Creation – Photo and videography": "content-creation",
      "Digital Strategy and Media Buying": "digital-media-buying-agency",
      "Social Media Management": "social-media-management",
      "360 Campaigns": "360-campaigns",
      "Lead Generation and Acquisition": "lead-generation-and-acquisition",
      "Research and Development": "research-and-development",
      "Character Creation 2D and 3D": "character-creation-2d-and-3d",
      "Branding": "branding",
      "Programmatic Advertising": "programmatic-advertising",
      "UI/UX Design": "ui-ux-design",
    };
    
    const formatServiceRoute = (serviceName) => {
      return serviceRouteMap1[serviceName] || formatRoute(serviceName); // Fallback to `formatRoute` if no mapping found
    };


    const handleChangeService = (e, val) => {
      e.preventDefault()
      if (val === 'previous') {
        if (indexPos > 0) {
          const index = indexPos - 1;
          setIndexPos(index)
          setIndividualService(services[index])
  
  
          // if (params.serviceDetailsId === "d2g") return;
          if (
            services[index].service_name === "lead-generation-and-acquisition-"
          ) { 
            navigate(`/service/lead-generation-and-acquisition`, {
              state: { index: index, data: services[index] },
            });
            return;
          } if(services[index].service_name === "Data Mining"){
            navigate(`/service/data-mining`, {
              state: { index: index, data: services[index] },
            });
            return;
          }
          else {
            navigate(`/service/${services[index].service_name}`, {
              state: { index: index, data: services[index] },
            });
          }
        }
  
          
      }
      else if (val === 'next') {
        if (indexPos < (services.length - 1)) {
          const index = indexPos + 1;
          setIndexPos(index)
          setIndividualService(services[index])
  
          // if(params.serviceDetailsId === 'd2g') return
          if (
            services[index].service_name === "lead-generation-and-acquisition-"
          ) {
            navigate(`/service/lead-generation-and-acquisition`, {
              state: { index: index, data: services[index] },
            });
          }   if(services[index].service_name === "Advisory Consulting"){
            navigate(`/service/advisory-consulting`, {
              state: { index: index, data: services[index] },
            });
            return;
          }else {
            navigate(`/service/${services[index].service_name}`, {
              state: { index: index, data: services[index] },
            });
          }
        }
        
      }
    } 

    const handledownloadOpen = () => {
      const link = document.createElement('a');
      link.href = '/images/LeadForge.pdf'; 
      link.download = 'Leadforge_brochure.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      };

      const handleClose = () => {
        setContactdetl({ firstname: '', lastname: '', email: '', company: '', phonenumber: "" })
        setError({ firstname: '', lastname: '', email: '', company: '', phonenumber: "" })
    
    
        setServiceavlb({
          branding: false,
          campaigns_360: false,
          content_creation: false,
          digital_strategy_media_buying: false,
          social_media_management: false,
          research_development: false,
          data_mining: false,
          lead_generation: false,
          websites_applications: false,
          advisory_consulting_services: false,
        });
        setOpen(false);
        var element = document.getElementById("html_main");
        element.classList.remove("modalOpen");
      };
    
      const handleContactdetail = (e) => {
        setContactdetl({ ...contactdetl, [e.target.name]: e.target.value })
        setError({ ...errror, [e.target.name]: '' })
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const [serviceavlb, setServiceavlb] = useState({
          branding: false,
          campaigns_360: false,
          content_creation: false,
          digital_strategy_media_buying: false,
          social_media_management: false,
          research_development: false,
          data_mining: false,
          lead_generation: false,
          websites_applications: false,
          advisory_consulting_services: false,
        })
      
        const handleServicedetail = (e) => {
          setServiceavlb({ ...serviceavlb, [e.target.name]: e.target.checked })
        }

        const validate = () => {
          if (!contactdetl.firstname && !contactdetl.email && !contactdetl.phonenumber) {
            setError({ ...errror, firstname: 'This field is required', email: 'This field is required', phonenumber: 'This field is required' })
            return false
          }
          else if (!contactdetl.firstname) {
            setError({ ...errror, firstname: 'This field is required' })
            return false
          }
          else if (!contactdetl.email) {
            setError({ ...errror, email: 'This field is required' })
            return false
          }
          else if (emailRegex.test(contactdetl.email) == false) {
            setError({ ...errror, email: 'Please enter valid email' })
            return false
          }
          else if (!contactdetl.phonenumber) {
            setError({ ...errror, phonenumber: 'This field is required' })
            return false
          }
          return true
        }

        const handleSubmit = (event) => {
          event.preventDefault();
          var valid = validate()
          if (valid == true) {
            setBtndisable(true)
            let body = {
              first_name: contactdetl.firstname,
              last_name: contactdetl.lastname,
              email: contactdetl.email,
              phone_number: contactdetl.phonenumber,
              company: contactdetl.company,
              services: serviceavlb
            }
            axios.post(process.env.REACT_APP_BASE_URL + 'Enquiry/createEnquiry', body).then((res) => {
              setBtndisable(false)
              setContactdetl({ firstname: '', lastname: '', email: '', company: '', phonenumber: "" })
              setServiceavlb({
                branding: false,
                campaigns_360: false,
                content_creation: false,
                digital_strategy_media_buying: false,
                social_media_management: false,
                research_development: false,
                data_mining: false,
                lead_generation: false,
                websites_applications: false,
                advisory_consulting_services: false,
              });
              setError({ firstname: '', lastname: '', email: '', company: '', phonenumber: "" })
              setOpen(false)
              toast.success('Success')
              window.open("https://api.theimpression.me/public/Theimpession-companyprofile.pdf");
            }).catch((err) => {
              setBtndisable(false)
              console.log(err)
            })
          }
        }
    return (
      <>

<Box className="portfolioDetail">
      <Slider {...settings} className="cs-gap-12 cs-arrow_style4">
          {individualService &&
            individualService.additionalimages?.length > 0 &&
            individualService.additionalimages.map((item, i) => (
              <PageHeadingService
                title="Service Details"
                //  bgSrc='/images/Leadforge Banner.jpg'
                bgSrc={item}
                pageLinkText={params.serviceDetailsId}
              />
            ))}
        </Slider>

      <Spacing lg="50" md="40" />

      <Div className="container">
        {/* {
          individualService && individualService.service_name.includes("LeadForge") ? <VideoModal
            videoSrc="/images/video/LeadForgeyoutubesize.mp4"
            bgUrl="/images/video_bg.jpeg"
          /> : 
          <Swiper
            navigation={true}
            pagination={{
              dynamicBullets: true,
            }}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
          >
            {individualService && individualService.additionalimages.map((src, i) => (
              <SwiperSlide key={i}><img src={process.env.REACT_APP_BASE_URL + src} alt="Details" className="cs-radius_15 w-100" /></SwiperSlide>))}
          </Swiper>
        } */}

        <Div className="cs-page_navigation cs-center">
          {services && services.length > 1 && (
            <>
              <Div>
                <Link
                  to={
                    indexPos > 0
                      ? `/service/${formatServiceRoute(services[indexPos - 1].service_name)}`
                      : "#"
                  }
                  className={`cs-text_btn cs-type1 ${indexPos === 0 ? "disabled" : ""
                    }`}
                  onClick={(e) => handleChangeService(e, "previous")}
                  disabled={indexPos === 0}
                >
                  <span> Prev Service</span>
                  <Icon icon="bi:arrow-right" />
                </Link>

              </Div>

              <Div>
                <Link
                  to={
                    indexPos < services.length - 1
                      ? `/service/${formatServiceRoute(services[indexPos + 1].service_name)}`
                      : "#"
                  }
                  className={`cs-text_btn ${indexPos === services.length - 1 ? "disabled" : ""
                    }`}
                  onClick={(e) => handleChangeService(e, "next")}
                  disabled={indexPos === services.length - 1}
                >
                  <span> Next Service</span>
                  <Icon icon="bi:arrow-right" />
                </Link>

              </Div>
            </>
          )}
        </Div>

        <Spacing lg="50" md="40" />

        <Div className="row">
          <Div className="col-lg-12">
            {individualService &&
              individualService.service_name !== "content-creation" &&
              individualService.service_name !==
              "digital-media-buying-agency" &&
              individualService.service_name !== "social-media-management" &&
              individualService.service_name !== "360-campaigns" &&
              individualService.service_name !==
              "lead-generation-and-acquisition-" &&
              individualService.service_name !== "research-and-development" &&
              individualService.service_name !==
                "character-creation-2d-and-3d" &&
              individualService.service_name !== "branding" &&
              individualService.service_name !== "programmatic-advertising" &&
              individualService.service_name !== "UI-UX-Design" && 
              individualService.service_name !== "Data Mining" &&
              individualService.service_name !== "Advisory Consulting" && (
                <>
                  <SectionHeading
                    mainTitle={
                      individualService && individualService.service_name
                    }
                    subtitle={
                      individualService && individualService.category_name
                    }
                  >
                    <Spacing lg="40" md="20" />
                    <p
                      dangerouslySetInnerHTML={{
                        __html:
                          individualService && individualService.description,
                      }}
                    ></p>
                  </SectionHeading>
                </>
              )}
          </Div>
        </Div>

        {/* <Div className="text-center mt-4">
          <Button onClick={handleClickOpen}  className="theme_btn_new">Download Our Portfolio</Button>
        </Div> */}
      

      <Spacing lg="20" md="20" />
          <Helmet>
               <meta name="robots" content="index, follow" />
                <title>Research & Development | The Impression</title>
                <meta
                  name="description"
                   content="Explore innovative research and development services at The Impression. Unlock growth with data-driven insights and tailored strategies for your business."
                />
          </Helmet>
        <SectionHeading
          mainTitle="Research & Development"
          caption="Innovating Through Research and Development"
          subtitle="Staying Ahead with Digital R&D Solutions"
          para="Driving innovation through meticulous research and development tailored to the digital consumer market. By uncovering emerging trends, analyzing consumer behaviors, and leveraging technological advancements, we empower brands to stay relevant and competitive."
        ></SectionHeading>

        <Div className="row">
          <Div className="col-xl-5 col-lg-7">
            <SectionHeading
              subtitle="What We Deliver with Research and Development"
              para="Empowering Brands with Data-Driven Insights"
            >
              <Box>
                <ul>
                  <li>
                    <h4>Trend Analysis:</h4> Identifying and adapting to the
                    latest digital and consumer trends to position your brand
                    ahead of competitors.
                  </li>
                  <Spacing lg="25" md="25" />

                  <li>
                    <h4>Behavioral Insights:</h4> Understanding consumer
                    behavior to design experiences that resonate and drive
                    engagement.
                  </li>
                  <Spacing lg="25" md="25" />

                  <li>
                    <h4>Technological Integration:</h4> Leveraging advancements
                    like AI and machine learning for cutting-edge solutions.
                  </li>
                  <Spacing lg="25" md="25" />

                  <li>
                    <h4>Custom Solutions:</h4> Tailored strategies aligned with
                    your brand’s goals to ensure maximum impact and relevance.
                  </li>
                </ul>
              </Box>
            </SectionHeading>
          </Div>

          <Div className="col-lg-5 offset-xl-2">
            <img
              src="/images/about_us.png"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
        <Spacing lg="30" md="20" />

        <Div className="row">
          <SectionHeading
            caption="Your Partner in Innovation and Growth"
            para="Why Choose The Impression for R&D?"
          >
            <Box>
              <ul>
                <li>
                  <h4 style={{ display: "inline" }}>Strategic Focus:</h4> We
                  prioritize insights that deliver measurable business outcomes.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4 style={{ display: "inline" }}>Tech-Driven Excellence:</h4>{" "}
                  Advanced tools and technologies inform every decision.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4 style={{ display: "inline" }}>Market Expertise:</h4> Deep
                  understanding of Dubai’s unique market dynamics ensures
                  locally relevant solutions.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4 style={{ display: "inline" }}>
                    Cross-Industry Applications:
                  </h4>{" "}
                  Proven success in applying R&D insights across industries like
                  e-commerce, healthcare, and hospitality.
                </li>
                <Spacing lg="25" md="25" />

                <li>
                  <h4 style={{ display: "inline" }}>Future-Ready Solutions:</h4>{" "}
                  Strategies designed to adapt to the rapidly changing digital
                  landscape.
                </li>
              </ul>
            </Box>
          </SectionHeading>
        </Div>
 </Div>
        <Spacing lg="100" md="80" />
        <Div className="text-center mt-4">
          <Button onClick={handledownloadOpen} className="theme_btn_new">
            Download Brochure
          </Button>
        </Div>
   <LeadforgeContact />
        <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="custom_modal"
        maxWidth="md"
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          className="modal_title"
          id="customized-dialog-title"
        >
          <div> Get a Quote </div>{" "}
          <div className="ml-auto">
            <Icon icon="mdi:close" onClick={handleClose} />
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <form className="row">
            <Div className="col-sm-6">
              <label className="cs-primary_color">First Name*</label>
              <input
                type="text"
                className="cs-form_field"
                value={contactdetl.firstname}
                name="firstname"
                onChange={handleContactdetail}
              />
              <p style={{ color: "red" }}> {errror.firstname}</p>
              <Spacing lg="20" md="20" />
            </Div>
            <Div className="col-sm-6">
              <label className="cs-primary_color">Last Name </label>
              <input
                type="text"
                className="cs-form_field"
                value={contactdetl.lastname}
                name="lastname"
                onChange={handleContactdetail}
              />
              <Spacing lg="20" md="20" />
            </Div>
            <Div className="col-sm-6">
              <label className="cs-primary_color">Company</label>
              <input
                type="text"
                className="cs-form_field"
                value={contactdetl.company}
                name="company"
                onChange={handleContactdetail}
              />
              <Spacing lg="20" md="20" />
            </Div>
            <Div className="col-sm-6">
              <label className="cs-primary_color">Email*</label>
              <input
                type="text"
                className="cs-form_field"
                value={contactdetl.email}
                name="email"
                onChange={handleContactdetail}
              />
              <p style={{ color: "red" }}> {errror.email}</p>
              <Spacing lg="20" md="20" />
            </Div>

            <Div className="col-sm-6">
              <label className="cs-primary_color">Phone Number*</label>
              <PhoneInput
                value={contactdetl.phonenumber}
                className="cs-form_field"
                defaultCountry="LB"
                onChange={(e) => {
                  setContactdetl({ ...contactdetl, phonenumber: e });
                  setError({ ...errror, phonenumber: "" });
                }}
              />
              <p style={{ color: "red" }}> {errror.phonenumber}</p>
              <Spacing lg="20" md="20" />
            </Div>
            <h5 className={colordata == "dark" ? "" : "contacttect"}>
              What services are you interested in?
            </h5>

            <Div className="col-sm-6">
              <FormControlLabel
                className="checkbox_div"
                control={
                  <Checkbox
                    defaultChecked
                    name="branding"
                    checked={serviceavlb.branding}
                    onChange={handleServicedetail}
                  />
                }
                label="Branding"
              />
            </Div>
            <Div className="col-sm-6">
              <FormControlLabel
                className="checkbox_div"
                control={
                  <Checkbox
                    defaultChecked
                    name="campaigns_360"
                    checked={serviceavlb.campaigns_360}
                    onChange={handleServicedetail}
                  />
                }
                label="360 Campaigns"
              />
            </Div>
            <Div className="col-sm-6">
              <FormControlLabel
                className="checkbox_div"
                control={
                  <Checkbox
                    defaultChecked
                    name="content_creation"
                    checked={serviceavlb.content_creation}
                    onChange={handleServicedetail}
                  />
                }
                label="Content Creation"
              />
            </Div>
            <Div className="col-sm-6">
              <FormControlLabel
                className="checkbox_div"
                control={
                  <Checkbox
                    defaultChecked
                    name="digital_strategy_media_buying"
                    checked={serviceavlb.digital_strategy_media_buying}
                    onChange={handleServicedetail}
                  />
                }
                label="Digital Strategy & Media Buying"
              />
            </Div>
            <Div className="col-sm-6">
              <FormControlLabel
                className="checkbox_div"
                control={
                  <Checkbox
                    defaultChecked
                    name="social_media_management"
                    checked={serviceavlb.social_media_management}
                    onChange={handleServicedetail}
                  />
                }
                label="Social Media Management"
              />
            </Div>
            <Div className="col-sm-6">
              <FormControlLabel
                className="checkbox_div"
                control={
                  <Checkbox
                    defaultChecked
                    name="research_development"
                    checked={serviceavlb.research_development}
                    onChange={handleServicedetail}
                  />
                }
                label="Research & Development"
              />
            </Div>
            <Div className="col-sm-6">
              <FormControlLabel
                className="checkbox_div"
                control={
                  <Checkbox
                    defaultChecked
                    name="data_mining"
                    checked={serviceavlb.data_mining}
                    onChange={handleServicedetail}
                  />
                }
                label="Data Mining"
              />
            </Div>
            <Div className="col-sm-6">
              <FormControlLabel
                className="checkbox_div"
                control={
                  <Checkbox
                    defaultChecked
                    name="lead_generation"
                    checked={serviceavlb.lead_generation}
                    onChange={handleServicedetail}
                  />
                }
                label="Lead Generation"
              />
            </Div>
            <Div className="col-sm-6">
              <FormControlLabel
                className="checkbox_div"
                control={
                  <Checkbox
                    defaultChecked
                    name="websites_applications"
                    checked={serviceavlb.websites_applications}
                    onChange={handleServicedetail}
                  />
                }
                label="BrandWebsites & Applicationsing"
              />
            </Div>
            <Div className="col-sm-6">
              <FormControlLabel
                className="checkbox_div"
                control={
                  <Checkbox
                    defaultChecked
                    name="advisory_consulting_services"
                    checked={serviceavlb.advisory_consulting_services}
                    onChange={handleServicedetail}
                  />
                }
                label="Advisory & Consulting Services"
              />
            </Div>
          </form>
        </DialogContent>
        <DialogActions>
          {btndisable ? (
            <button className="cs-btn cs-style1">
              <span>Please wait...</span>
            </button>
          ) : (
            <button className="cs-btn cs-style1" onClick={handleSubmit}>
              <span>Send Message</span>
              <Icon icon="bi:arrow-right" />
            </button>
          )}
        </DialogActions>
      </BootstrapDialog>
      </Box>
        {/* Testimonials */}

        {/* <Div className="container">
          <SectionHeading subtitle="Frequently Asked Questions About Digital R&D" />
          <Div className="row">
            <Div
              className={
                accordionDataRDInDigitalMarketing.length > 7 ? "col-xl-5 col-lg-6" : ""
              }
            >
              <Accordion name="RDInDigitalMarketing" />
            </Div>

            {accordionDataRDInDigitalMarketing.length > 7 && (
              <Div className="col-xl-5 col-lg-6">
                <Accordion name="RDInDigitalMarketing" isSecondColumn={true} />
              </Div>
            )}
          </Div>
        </Div> */}
      </>
    );
}

export default ResearchAndDevelopment