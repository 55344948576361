import { Route, Routes, unstable_HistoryRouter, useLocation, useNavigate } from 'react-router-dom';
import { ContactProvider } from "./components/Context/Contactcontext";
import AboutPage from './components/Pages/AboutPage';
import BlogPage from './components/Pages/BlogPage';
import BlogDetailsPage from './components/Pages/BlogDetailsPage';
import ContactPage from './components/Pages/ContactPage';
import ErrorPage from './components/Pages/ErrorPage';
import Home from './components/Pages/Home';
import PortfolioDetailsPage from './components/Pages/PortfolioDetailsPage';
import ProjectDetailsPage from './components/Pages/ProjectDetailsPage';

import ServiceDetailsPage from './components/Pages/ServiceDetailsPage';
import ServicesPage from './components/Pages/ServicesPage';
import TeamPage from './components/Pages/TeamPage';
import PortfolioPage from './components/Pages/PortfolioPage';
import GalleryPage from './components/Pages/GalleryPage';
import GalleryPageDetails from './components/Pages/GallerypageDetails';
import ContentCreationPage from './components/Pages/D2CServices/ContentCreationPage';
import DigitalStatergy from './components/Pages/D2CServices/DigitalStatergy';
import SocialMediaManagement from './components/Pages/D2CServices/SocialMediaManagement';
import Campaigns from './components/Pages/D2CServices/Campaigns';
import LeadGeneration from './components/Pages/D2CServices/LeadGeneration';
import ResearchAndDevelopment from './components/Pages/D2CServices/ResearchAndDevelopment';
import Branding from './components/Pages/D2CServices/Branding';
import ProgrammaticAdvertising from './components/Pages/D2CServices/ProgrammaticAdvertising';
import UiUxDesign from './components/Pages/D2CServices/UiUxDesign';
import CharacterCreation from './components/Pages/D2CServices/CharacterCreation';
import DataMining from './components/Pages/D2GServices/DataMining';
import AdvisoryConsulting from './components/Pages/D2GServices/AdvisoryConsulting';
import LeadforgeContent from './components/Pages/D2BServices/LeadforgeContent';
import ProjectPage from './components/Pages/ProjectPage';
import Allservice from './components/Pages/Allservice';
import TeamDetails from './components/Pages/TeamDetails';
import PhotographyAgencyHome from './components/Pages/PhotographyAgencyHome';
import CreativePortfolioHome from './components/Pages/CreativePortfolioHome';
import DigitalAgencyHome from './components/Pages/DigitalAgencyHome';
import MarketingAgencyHome from './components/Pages/MarketingAgencyHome';
import ShowcasePortfolioHome from './components/Pages/ShowcasePortfolioHome';
import CaseStudyShowcaseHome from './components/Pages/CaseStudyShowcaseHome';
import Layout from './components/Layout';
import CaseStudyDetailsPage from './components/Pages/CaseStudyDetailsPage';
import FaqPage from './components/Pages/FaqPage';
import { Box } from '@mui/material';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import CareerPage from './components/Pages/CareerPage';
import PortfolioDetailsPageLeadForge from './components/Pages/PortfolioDetailsPageLeadForge';
// import DataMining from './components/Pages/DataMining';
import PortfolioDetailsPageX from './components/Pages/PortfolioDetailsPageX';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

function App() {

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Check if the current path ends with a slash
    if (window.location.pathname.endsWith('/')) {
      // Redirect to the same path without the slash
      navigate(window.location.pathname.slice(0, -1), { replace: true });
    }
  }, [navigate]);


  // Generate the current canonical URL based on the current route
  const currentUrl = window.location.origin + location.pathname;

  // Remove trailing slash if it exists
  const canonicalUrl = currentUrl.endsWith('/') ? currentUrl.slice(0, -1) : currentUrl;
console.log(canonicalUrl,'canonicalUrlcanonicalUrl');


  return (
    <Box sx={{ width: '100%', bgcolor: 'background.default', color: 'text.primary' }}>
      <ContactProvider>

      <Helmet>
          <link rel="canonical" href={canonicalUrl} />
        </Helmet>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="photography-agency" element={<PhotographyAgencyHome />} />
            <Route path="digital-agency" element={<DigitalAgencyHome />} />
            <Route path="marketing-agency" element={<MarketingAgencyHome />} />
            <Route path="about" element={<AboutPage />} />
            {/* <Route path="service" element={<ServicesPage />} /> */}

            {/* <Route path="allservice" element={<Allservice />} /> */}

            {/* <Route path="service/:serviceDetailsId" element={<ServiceDetailsPage />} /> */}

            {/* <Route path="portfolio" element={<PortfolioPage />} />
          <Route path="portfolio/:portfolioDetailsId" element={<PortfolioDetailsPage />} /> */}

            <Route path="service" element={<PortfolioPage />} />

            <Route path="service/content-creation" element={<ContentCreationPage />} />
            <Route path="service/digital-media-buying-agency" element={<DigitalStatergy />} />
            <Route path="service/social-media-management" element={<SocialMediaManagement />} />
            <Route path="service/360-campaigns" element={<Campaigns />} />  
            <Route path="service/lead-generation-and-acquisition" element={<LeadGeneration />} /> 
            <Route path="service/research-and-development" element={<ResearchAndDevelopment />} />  
            <Route path="service/character-creation-2d-and-3d" element={<CharacterCreation />} /> 
            <Route path="service/branding" element={<Branding />} />    
            <Route path="service/programmatic-advertising" element={<ProgrammaticAdvertising />} />  
            <Route path="service/ui-ux-design" element={<UiUxDesign />} />   


            <Route path="service/data-mining" element={<DataMining />} />     
            <Route path="service/advisory-consulting" element={<AdvisoryConsulting />} /> 

            {/* <Route path="service/leadforge" element={<LeadforgeContent />} />  */}
            {/* <Route path="service/:serviceDetailsId" element={<PortfolioDetailsPageX />} /> */}

            <Route path="leadforge" element={< PortfolioDetailsPageLeadForge/>} />
            {/* <Route path="service/:d2g" element={< DataMining/>} /> */}

            <Route path="ourwork" element={<ProjectPage />} />
            <Route path="ourwork/:workDetailsId" element={<ProjectDetailsPage />} />
            <Route path='career' element={<CareerPage />} />
            <Route path="gallery" element={<GalleryPage />} />
            <Route path="gallerytest" element={<GalleryPageDetails />} />

            <Route path="blog" element={<BlogPage />} />
            <Route path="blog/:blogDetailsId" element={<BlogDetailsPage />} />
            <Route path="contact" element={<ContactPage />} />
            <Route path="team" element={<TeamPage />} />
            <Route path="team/:teamDetails" element={<TeamDetails />} />
            <Route path="/case-study/:caseStudyDetailsId" element={<CaseStudyDetailsPage />} />
            <Route path="faq" element={<FaqPage />} />
            <Route path="faq/:name" element={<FaqPage />} />
            
          </Route>
          <Route path="/" element={<Layout headerVariant="cs-site_header_full_width" />} >
            <Route path="creative-portfolio" element={<CreativePortfolioHome />} />
            <Route path="showcase-portfolio" element={<ShowcasePortfolioHome />} />
            <Route path="case-study-showcase" element={<CaseStudyShowcaseHome />} />
          </Route>
          <Route path="*" element={<ErrorPage />} />

        </Routes>
      </ContactProvider>
      <FloatingWhatsApp  
      // phoneNumber="+971562117034"    
        phoneNumber="+971 56 211 7034"
        accountName="Impression Team"
        avatar="/images/theimpressionProPic.jpg"
        allowEsc
        allowClickAway
        notification
        notificationSound />
    </Box>
  );
}

export default App;
