import React, { useEffect, useState } from 'react';
import Card from '../Card';
import FunFact from '../FunFact';
import Hero from '../Hero';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import Cta from '../Cta';
import LogoList from '../LogoList';
import MovingText from '../MovingText';
import PortfolioSliderProject from '../Slider/PortfolioSliderProject';
import PostSlider from '../Slider/PostSlider';
import TestimonialSlider from '../Slider/TestimonialSlider';
import TeamSlider from '../Slider/TeamSlider';
import VideoModal from '../VideoModal';
import TimelineSlider from '../Slider/TimelineSlider';
import { pageTitle } from '../../helper';
import axios from 'axios';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { styled } from '@mui/material/styles';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Icon } from '@iconify/react';
import { Box, IconButton } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useContact } from "../../components/Context/Contactcontext";
import VerticalLinks from '../VerticalLinks';
import { useSelector } from "react-redux"


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2),
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1),
	},
}));


export default function Home() {
	const location = useLocation()
	const { contactdetails } = useContact();

	
	const [contactdetl, setContactdetl] = useState({ firstname: '', lastname: '', email: '', company: '', phonenumber: "" })
	const [errror, setError] = useState({ firstname: '', lastname: '', email: '', company: '', phonenumber: "" })
	const [btndisable, setBtndisable] = useState(false)
	const colordata = useSelector((state) => state && state.colordata)

	const [serviceavlb, setServiceavlb] = useState({
		branding: false,
		campaigns_360: false,
		content_creation: false,
		digital_strategy_media_buying: false,
		social_media_management: false,
		research_development: false,
		data_mining: false,
		lead_generation: false,
		websites_applications: false,
		advisory_consulting_services: false,
	})
	pageTitle('Home');
	// Hero Social Links
	const heroSocialLinks = [
		{
			name: 'Instagram',
			links: contactdetails[0]?.instagram			,
		},
		{
			name: 'LinkedIn',
			links: contactdetails[0]?.linkedin,
		},
		{
			name: 'Twitter',
			links: contactdetails[0]?.twitter,
		},
	];

	const handleServicedetail = (e) => {
		setServiceavlb({ ...serviceavlb, [e.target.name]: e.target.checked })
	}

	// FunFact Data
	const funfaceData = [
		{
			title: 'Leads Generated',
			factNumber: '500K',
		},
		{
			title: 'Team Members',
			factNumber: '60',
		},
		{
			title: 'Projects Delivered',
			factNumber: '10k',
		},
		{
			title: 'Campaigns Spend',
			factNumber: '$50M',
		},
	];

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);

	useEffect(() => {
		getPrimaryService()
	}, []);

	const [primaryService, setPrimaryService] = React.useState([])
	const getPrimaryService = async () => {
		await axios.get(process.env.REACT_APP_BASE_URL + `service/getPrimaryServicesPublic`).then((res) => {
			setPrimaryService(res.data.getPrimay)
		}).catch((error) => {
			console.log(error, "error");
		});
	};

	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
		var element = document.getElementById("html_main");
		element.classList.add("modalOpen");
	};

	const handleClose = () => {
		setContactdetl({ firstname: '', lastname: '', email: '', company: '', phonenumber: "" })
		setError({ firstname: '', lastname: '', email: '', company: '', phonenumber: "" })
		setServiceavlb({
			branding: false,
			campaigns_360: false,
			content_creation: false,
			digital_strategy_media_buying: false,
			social_media_management: false,
			research_development: false,
			data_mining: false,
			lead_generation: false,
			websites_applications: false,
			advisory_consulting_services: false,
		});
		setOpen(false);
		var element = document.getElementById("html_main");
		element.classList.remove("modalOpen");
	};

	const handleContactdetail = (e) => {
		setContactdetl({ ...contactdetl, [e.target.name]: e.target.value })
		setError({ ...errror, [e.target.name]: '' })
	}

	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	const validate = () => {
		if (!contactdetl.firstname && !contactdetl.email && !contactdetl.phonenumber) {
			setError({ ...errror, firstname: 'This field is required', email: 'This field is required', phonenumber: 'This field is required' })
			return false
		}
		else if (!contactdetl.firstname) {
			setError({ ...errror, firstname: 'This field is required' })
			return false
		}
		else if (!contactdetl.email) {
			setError({ ...errror, email: 'This field is required' })
			return false
		}
		else if (emailRegex.test(contactdetl.email) == false) {
			setError({ ...errror, email: 'Please enter valid email' })
			return false
		}
		else if (!contactdetl.phonenumber) {
			setError({ ...errror, phonenumber: 'This field is required' })
			return false
		}
		return true
	}

	const handleSubmit = (event) => {
		event.preventDefault();
		var valid = validate()
		if (valid == true) {
			setBtndisable(true)
			let body = {
				first_name: contactdetl.firstname,
				last_name: contactdetl.lastname,
				email: contactdetl.email,
				phone_number: contactdetl.phonenumber,
				company: contactdetl.company,
				services: serviceavlb
			}
			axios.post(process.env.REACT_APP_BASE_URL + 'Enquiry/createEnquiry', body).then((res) => {
				setBtndisable(false)
				setContactdetl({ firstname: '', lastname: '', email: '', company: '', phonenumber: "" })
				setServiceavlb({
					branding: false,
					campaigns_360: false,
					content_creation: false,
					digital_strategy_media_buying: false,
					social_media_management: false,
					research_development: false,
					data_mining: false,
					lead_generation: false,
					websites_applications: false,
					advisory_consulting_services: false,
				});
				setError({ firstname: '', lastname: '', email: '', company: '', phonenumber: "" })
				setOpen(false)
				toast.success('Success')
				window.open("https://api.theimpression.me/public/Theimpession-companyprofile.pdf");
			}).catch((err) => {
				setBtndisable(false)
				console.log(err)
			})
		}
	}
  const formatRoute = (serviceName) => {
		return serviceName
		  .replace(/–/g, '-') 
		  .replace(/\s+/g, '-') 
		  .toLowerCase() 
		  .replace(/-$/, '');  
	  };
    const serviceRouteMap = {
      "Content Creation – Photo and videography": "content-creation",
      "content-creation": "Content Creation – Photo and Videography",
      "Digital Strategy and Media Buying": "digital-media-buying-agency",
      "Social Media Management": "social-media-management",
      "360 Campaigns": "360-campaigns",
      "Lead Generation and Acquisition": "lead-generation-and-acquisition",
      "Research and Development": "research-and-development",
      "Character Creation 2D and 3D": "character-creation-2d-and-3d",
      "Branding": "branding",
      "Programmatic Advertising": "programmatic-advertising",
      "UI/UX Design": "ui-ux-design",
    };
    
    const formatServiceRoute = (serviceName) => {
      return serviceRouteMap[serviceName] || formatRoute(serviceName); // Fallback to `formatRoute` if no mapping found
    };
    
	return (
    <>
      {/* Start Hero Section */}
      <Box className="home_banner_section">
        <Hero
          title="We Don’t <br/>Do Normal"
          subtitle=""
          btnText="Get a Quote"
          btnLink="/contact"
          scrollDownId="#service"
          socialLinksHeading="Follow Us"
          heroSocialLinks={heroSocialLinks}
          bgImageUrl="/images/hero_bg.jpeg"
        />
        <Link
          to={""}
          className="cs-text_btn get_quote_link"
          onClick={handleClickOpen}
        >
          <>
            <span>Get a Quote</span>
            <Icon icon="bi:arrow-right" />
          </>
        </Link>
        <VerticalLinks data={heroSocialLinks} title="Follow Us" />
      </Box>
      <Div className="text-center">
        <>
          <Spacing lg="65" md="40" />
          <span className="cs-text_btn">
            <span>Load More</span>
            <Icon icon="bi:arrow-right" />
          </span>
        </>
      </Div>

      {/* Start FunFact Section */}
      <div className="container">
        <FunFact
          variant="cs-type1"
          title="Our Fun Facts"
          subtitle="Who said numbers have to be boring? We turn them into stories. Welcome to the narrative!"
          data={funfaceData}
        />
      </div>
      {/* End FunFact Section */}

      {/* Start Service Section */}
      <Spacing lg="60" md="30" />
      <Div id="service">
        <Div className="cs-gradient_bg_1 cs-shape_wrap_3 cs-parallax">
          <Div className="cs-shape_3 cs-to_up">
            <img src="/images/shape_1.svg" alt="Shape" />
			
          </Div>
		  <Div 
		  className="row"
		  style={{
			marginBottom:"15px",
      color:"white",
		}} >
			<Div className="cs-cta cs-style1 cs-bg text-center cs-shape_wrap_1 cs-position_1" >
				<p >
				Empowering businesses with innovative campaigns, data-driven strategies, and services like PPC advertising, SEO optimization,and digital marketing to drive growth across diverse industries.
				</p>
			</Div>
        </Div>
        </Div>
       

        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Some of the things we Master"
                subtitle="What We Can Do"
                btnText="See All Services"
                btnLink="/service"
              />
              <Spacing lg="90" md="45" />
            </Div>

            <Div
              className={
                primaryService.length < 0
                  ? "col-xl-8 d-flex align-items-center justify-content-center"
                  : "col-xl-8"
              }
            >
              <Div className="row services_card">
                {primaryService && primaryService.length > 0 ? (
                  primaryService.map((item, index) => (
                    <>
                      <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                      <Div className="col-lg-3 col-sm-6" key={index}>
                      <Link 
                        to={`/service/${formatServiceRoute(item.service_name)}`} 
                        className="card-link" 
                        title={item.service_name}
                      >
                        <div className="card-content">
                          <img 
                            src={process.env.REACT_APP_BASE_URL + item.image} 
                            alt={item.service_name} 
                            className="card-image aspect_ratio_1"
                          />
                        </div>
                      </Link>
                      <Spacing lg="0" md="30" />
                    </Div>
                    </>
                  ))
                ) : (
                  <h2 className="cs-cta_title cs-semi_bold cs-m0 text-center no_data">
                    No Services Found
                  </h2>
                )}
              </Div>
            </Div>

            <Div className="cs-cta cs-style1 cs-bg text-center cs-shape_wrap_1 cs-position_1">
              <Div className="cs-shape_1" />
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Service Section */}

      {/* Start Portfolio Section */}
      <Div>
        <Div className=" portfolio_slider">
          <SectionHeading
            title="Portfolio to Explore"
            subtitle="Latest Projects"
            variant="cs-style1 text-center"
          />
          <Spacing lg="70" md="30" />

          <PortfolioSliderProject />
        </Div>
      </Div>
	  
      {/* End Portfolio Section */}

      {/* Start Awards Section */}
      {/* <Spacing lg="100" md="80" />
			<Div className="cs-shape_wrap_2 awards_section">
				<Div className="cs-shape_2">
					<Div />
				</Div>
				<Div className="container">
					<Div className="row">
						<Div className="col-xl-4">
							<SectionHeading
								title="We get multiple awards"
								subtitle="Our Recognitions"
								variant="cs-style1"
							/>
							<Spacing lg="90" md="45" />
						</Div>
						<Div className="col-xl-7 offset-xl-1">
							<TimelineSlider />
						</Div>
					</Div>
				</Div>
			</Div> */}
      {/* End Awards Section */}

      {/* Start Video Block Section */}
      {/* videoSrc="https://www.youtube.com/watch?v=VcaAVWtP48A" */}
      <Spacing lg="130" md="70" />
	  <Div className="container">
		<Div className="row">
			<p>Revolutionizing lead generation with data-driven insights, advanced buyer intent research, and tailored strategies to maximize conversion rates across industries.</p>
		</Div>
	  </Div>
      <Spacing lg="130" md="70" />
      <Div className="container">
        <h2 className="cs-font_50 cs-m0 text-center cs-line_height_4">
          Lead the Evolution!
        </h2>
        <Spacing lg="70" md="20" />
        <VideoModal
          videoSrc="/images/video/LeadForgeyoutubesize.mp4"
          bgUrl="/images/Home-page-lead-forge-thumbnail.jpg"
        />
        {/* <h2 className="cs-font_50 cs-m0 text-center cs-line_height_4">
					Lead the Evolution!
				</h2>
				<Spacing lg="70" md="20" />
				<div class="video-container">
					<video width="640" height="360" controls>
						<source src="/images/video/LeadForgeyoutubesize.mp4" type="video/mp4" />
						Your browser does not support the video tag.
					</video>
				</div> */}
      </Div>
      {/* End Video Block Section */}

      {/* Start Team Section */}
      {/* <Spacing lg="145" md="80" />
			<Div className="container team_slider">
				<SectionHeading
					title="Awesome team <br/>members"
					subtitle="Our Team"
					variant="cs-style1"
				/>
				<Spacing lg="85" md="45" />
				<TeamSlider />
			</Div> */}
      {/* <Spacing lg="150" md="80" /> */}
      {/* End Team Section */}

      {/* Start Testimonial Section */}
      {/* <TestimonialSlider /> */}
      {/* End Testimonial Section */}

      {/* Start Blog Section */}
      {/* <Spacing lg="150" md="80" />
			<Div className="cs-shape_wrap_4 recent_blogs">
				<Div className="cs-shape_4"></Div>
				<Div className="cs-shape_4"></Div>
				<Div className="container">
					<Div className="row">
						<Div className="col-xl-4">
							<SectionHeading
								title="Explore Recent Publications! "
								subtitle="Our Blog"
								btnText="View More Blog"
								btnLink="/blog"
							/>
							<Spacing lg="90" md="45" />
						</Div>
						<Div className="col-xl-7 offset-xl-1">
							<Div className="cs-half_of_full_width">
								<PostSlider />
							</Div>
						</Div>
					</Div>
				</Div>
			</Div> */}
      {/* End Blog Section */}

      {/* Start MovingText Section */}
      <Spacing lg="50" md="20" />
      <MovingText text="We Don’t Do Normal We Don’t Do Normal We Don’t Do Normal" />
      <Spacing lg="50" md="20" />
      {/* End MovingText Section */}

      {/* Start LogoList Section */}
      <Div className="container">
        <LogoList />
      </Div>
      <Spacing lg="50" md="20" />
      {/* End LogoList Section */}

      {/* Start CTA Section */}

      <Div className="container cta_bg_image">
        <Cta
          title="Let’s Create <br />something Remarkable!"
          btnText="Download Our Portfolio"
          btnLink="https://api.theimpression.me/public/Theimpession-companyprofile.pdf"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      {/* End Hero Section */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="custom_modal"
        maxWidth="md"
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          className="modal_title"
          id="customized-dialog-title"
        >
          <div> Get a Quote </div>{" "}
          <div className="ml-auto">
            <Icon icon="mdi:close" onClick={handleClose} />
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <form className="row">
            <Div className="col-sm-6">
              <label className="cs-primary_color">First Name*</label>
              <input
                type="text"
                className="cs-form_field"
                value={contactdetl.firstname}
                name="firstname"
                onChange={handleContactdetail}
              />
              <p style={{ color: "red" }}> {errror.firstname}</p>
              <Spacing lg="20" md="20" />
            </Div>
            <Div className="col-sm-6">
              <label className="cs-primary_color">Last Name </label>
              <input
                type="text"
                className="cs-form_field"
                value={contactdetl.lastname}
                name="lastname"
                onChange={handleContactdetail}
              />
              <Spacing lg="20" md="20" />
            </Div>
            <Div className="col-sm-6">
              <label className="cs-primary_color">Company</label>
              <input
                type="text"
                className="cs-form_field"
                value={contactdetl.company}
                name="company"
                onChange={handleContactdetail}
              />
              <Spacing lg="20" md="20" />
            </Div>
            <Div className="col-sm-6">
              <label className="cs-primary_color">Email*</label>
              <input
                type="text"
                className="cs-form_field"
                value={contactdetl.email}
                name="email"
                onChange={handleContactdetail}
              />
              <p style={{ color: "red" }}> {errror.email}</p>
              <Spacing lg="20" md="20" />
            </Div>

            <Div className="col-sm-6">
              <label className="cs-primary_color">Phone Number*</label>
              <PhoneInput
                value={contactdetl.phonenumber}
                className="cs-form_field"
                defaultCountry="LB"
                onChange={(e) => {
                  setContactdetl({ ...contactdetl, phonenumber: e });
                  setError({ ...errror, phonenumber: "" });
                }}
              />
              <p style={{ color: "red" }}> {errror.phonenumber}</p>
              <Spacing lg="20" md="20" />
            </Div>
            <h5 className={colordata == "dark" ? "" : "contacttect"}>
              What services are you interested in?
            </h5>

            <Div className="col-sm-6">
              <FormControlLabel
                className="checkbox_div"
                control={
                  <Checkbox
                    defaultChecked
                    name="branding"
                    checked={serviceavlb.branding}
                    onChange={handleServicedetail}
                  />
                }
                label="Branding"
              />
            </Div>
            <Div className="col-sm-6">
              <FormControlLabel
                className="checkbox_div"
                control={
                  <Checkbox
                    defaultChecked
                    name="campaigns_360"
                    checked={serviceavlb.campaigns_360}
                    onChange={handleServicedetail}
                  />
                }
                label="360 Campaigns"
              />
            </Div>
            <Div className="col-sm-6">
              <FormControlLabel
                className="checkbox_div"
                control={
                  <Checkbox
                    defaultChecked
                    name="content_creation"
                    checked={serviceavlb.content_creation}
                    onChange={handleServicedetail}
                  />
                }
                label="Content Creation"
              />
            </Div>
            <Div className="col-sm-6">
              <FormControlLabel
                className="checkbox_div"
                control={
                  <Checkbox
                    defaultChecked
                    name="digital_strategy_media_buying"
                    checked={serviceavlb.digital_strategy_media_buying}
                    onChange={handleServicedetail}
                  />
                }
                label="Digital Strategy & Media Buying"
              />
            </Div>
            <Div className="col-sm-6">
              <FormControlLabel
                className="checkbox_div"
                control={
                  <Checkbox
                    defaultChecked
                    name="social_media_management"
                    checked={serviceavlb.social_media_management}
                    onChange={handleServicedetail}
                  />
                }
                label="Social Media Management"
              />
            </Div>
            <Div className="col-sm-6">
              <FormControlLabel
                className="checkbox_div"
                control={
                  <Checkbox
                    defaultChecked
                    name="research_development"
                    checked={serviceavlb.research_development}
                    onChange={handleServicedetail}
                  />
                }
                label="Research & Development"
              />
            </Div>
            <Div className="col-sm-6">
              <FormControlLabel
                className="checkbox_div"
                control={
                  <Checkbox
                    defaultChecked
                    name="data_mining"
                    checked={serviceavlb.data_mining}
                    onChange={handleServicedetail}
                  />
                }
                label="Data Mining"
              />
            </Div>
            <Div className="col-sm-6">
              <FormControlLabel
                className="checkbox_div"
                control={
                  <Checkbox
                    defaultChecked
                    name="lead_generation"
                    checked={serviceavlb.lead_generation}
                    onChange={handleServicedetail}
                  />
                }
                label="Lead Generation"
              />
            </Div>
            <Div className="col-sm-6">
              <FormControlLabel
                className="checkbox_div"
                control={
                  <Checkbox
                    defaultChecked
                    name="websites_applications"
                    checked={serviceavlb.websites_applications}
                    onChange={handleServicedetail}
                  />
                }
                label="BrandWebsites & Applicationsing"
              />
            </Div>
            <Div className="col-sm-6">
              <FormControlLabel
                className="checkbox_div"
                control={
                  <Checkbox
                    defaultChecked
                    name="advisory_consulting_services"
                    checked={serviceavlb.advisory_consulting_services}
                    onChange={handleServicedetail}
                  />
                }
                label="Advisory & Consulting Services"
              />
            </Div>
          </form>
        </DialogContent>
        <DialogActions>
          {btndisable ? (
            <button className="cs-btn cs-style1">
              <span>Please wait...</span>
            </button>
          ) : (
            <button className="cs-btn cs-style1" onClick={handleSubmit}>
              <span>Send Message</span>
              <Icon icon="bi:arrow-right" />
            </button>
          )}
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
